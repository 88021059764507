import React from "react";
import { sanDiegoImages } from "../../../utils/imageResources";

import SceneObject from "../../SceneObject";


const Sand = React.memo(props => {
  return (
    <SceneObject
      name="Sand"
      transform={{
        position: props.transform.position || { x: -500, y: 160 },
        scale: props.transform.scale || { x: 4000, y: 200 },
      }}
      $imgUrl={sanDiegoImages.SandImg}
    />
  );
});

export default Sand;
