import React from "react";
import { sanDiegoImages } from "../../../utils/imageResources";

import SceneObject from "../../SceneObject";



const SurfBoards = React.memo(props => {
    return (
        <SceneObject
            name="SurfBoards"
            transform={{
                position: props.transform.position,
                scale: props.transform.scale,
                rotation: props.transform.rotation,
                opacity: 1
            }}
            $imgUrl={sanDiegoImages.SurfBoardsImg}
        />
    );
});

export default SurfBoards;
