// LinkedInProfile.tsx
import React from 'react';

interface LinkedInProfileProps {
    name: string;
    position: string;
    location: string;
    summary: string;
    profileImageURL: string;
    linkedInURL: string;
}

const LinkedInProfile: React.FC<LinkedInProfileProps> = ({
    name,
    position,
    location,
    summary,
    profileImageURL,
    linkedInURL,
}) => {

    const navigateToLinkedIn = () => {
        window.location.href = linkedInURL;
    };

    return (
        <div className="profile-container" onClick={navigateToLinkedIn}>
            <img
                src={profileImageURL}
                alt={`${name}'s LinkedIn Profile`}
                style={{ width: '200px' }}
                className="profile-image"
            />
            <div className="profile-details">
                <h1>{name}</h1>
                <h2>{position}</h2>
                <h3>{location}</h3>
                <p>{summary}</p>
            </div>
        </div>
    );
};

export default LinkedInProfile;
