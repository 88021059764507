import React from 'react';
import styled from 'styled-components';

interface CardTextProps {
  data: any;
}


const ImageContainer = styled.div<{ width: number }>`
  position: relative;
  width: ${(props) => props.width}px; // Use props to set the width
  height: ${(props) => props.width}px; // Use props to set the width;
`;

const OverlayImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Content = styled.ul<{ width: number, fontSize: number }>`
  position: fixed;
  top: 0px;  // Adjust this based on where you want it
  left: 0px;  // Adjust this based on where you want it
  width: ${(props) => props.width - 60}px; // Use props to set the width
  color: white;
  margin-top: 15px;
  padding-left: 35px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-size: ${(props) => props.fontSize}px;
`;


const CardTextOverlay: React.FC<CardTextProps> = ({ data }) => {

  const fontSize: number = data.fontSize;
  const width: number = data.width;
  const imageUrl: string = data.imageUrl;
  const content: string[] = data.content;

  return (
    <ImageContainer width={width}>
      <OverlayImage src={imageUrl} alt={imageUrl} />
      <TextOverlay>
        <Content width={width} fontSize={fontSize}>
          {content.map((sentence, index) => (
            <p key={index} style={{ fontWeight: "bold" }}>{sentence}</p>
          ))}
        </Content>
      </TextOverlay>
    </ImageContainer>
  );
};

export default CardTextOverlay;
