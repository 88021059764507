import React from "react";
import { useWindowSizeAndObjectPlacement } from "../../../utils/useWindowSize";
import SceneObject from "../../SceneObject";
import PalmTree from "./PalmTrees";

const SanDiegoEnvironmentLayer = React.memo(props => {

  const { height } = useWindowSizeAndObjectPlacement();

  const header_x = -310;
  //   const topDropKeyframes = props => keyframes`
  //   100% {left: ${header_x}px; bottom: ${yCoordinate - yCoordinate / 3}px;}
  // `;

  //   const topDropAnimationCSS = css`
  //   ${topDropKeyframes} 3s ease-in forwards;
  // `;

  return (
    <SceneObject {...props} name="SanDiego Environment">
      <SceneObject
        name="MAIN ROAD"
        color="grey"
        transform={{
          position: { x: -1200, y: 0 },
          scale: { x: 6000, y: 170 }
        }}
      />

      <PalmTree transform={{ position: { x: -650, y: 200 }, scale: { x: 450, y: 1000 } }} type="1" />

      {/* <SceneObject
        name="TEXT - San Diego"
        transform={{
          position: { x: header_x, y: yCoordinate-yCoordinate/4 },
          scale: { x: 800, y: 500 },
          sdposition: { x: header_x, y: 600 },  // Small screen position
          sdscale: { x: 300, y: 300 } // Small screen position
        }}
        $imgUrl={headerImages.Header02}
        animation={props.scroll > 5000 ? topDropAnimationCSS : ""}
      /> */}

      <SceneObject
        name="TEXT - San Diego"
        transform={{
          position: { x: header_x, y: height - 600 },
          scale: { x: 800, y: 500 },
          sdposition: { x: header_x, y: height / 2 },  // Small screen position
          sdscale: { x: 300, y: 300 } // Small screen position
        }}
      >
        <h3 className="dropping-text  dropping-text-4" style={{ animationDelay: '0s' }}>Live and Work in</h3>
        <h3 className="dropping-text  dropping-text-4" style={{ animationDelay: '0.5s' }}>San Diego, California</h3>
      </SceneObject>




      <PalmTree transform={{ position: { x: 3000, y: 170 }, scale: { x: 400, y: 1000 } }} type="3" />


      {/* <SceneObject
        name="San Diego Text Card"
        transform={{
          relativePosition: { x: 3280, y: 180 },
         dimensions: { x: 400, y: 400 },
          smallScreenDimensions: { x: 3280, y: 270 },  // Small screen position
          smallScreenDimensions: { x: 300, y: 300 }, // Small screen position
          opacity: props.scroll > 10500 && props.scroll < 12000 ? 1 : 0.3
        }}>
        <CardTextOverlay
          data={areaTextData["SanDiegoText"]}
        />
      </SceneObject> */}

      <PalmTree transform={{ position: { x: 3500, y: 170 }, scale: { x: 400, y: 1000 } }} type="3" />

    </SceneObject>
  );
});

export default SanDiegoEnvironmentLayer;
