import React from 'react';

interface PlayPauseProps {
    size?: number;
    color?: string;
    isPlaying: boolean;
}

const PlayPauseIcons: React.FC<PlayPauseProps> = ({ size = 24, color = '#000', isPlaying }) => {
    return (
        <div style={{ cursor: 'pointer' }}>
            {isPlaying ? (
                <svg
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    fill={color}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect x="6" y="4" width="4" height="16" />
                    <rect x="14" y="4" width="4" height="16" />
                </svg>
            ) : (
                <svg
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                    fill={color}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M8 5v14l11-7z" />
                </svg>
            )}
        </div>
    );
};

export default PlayPauseIcons;
