import { textBackgroundImages } from "../../utils/imageResources";


interface ResumeData {
    width: number;
    imageUrl?: string;  // Optional, since you might compute it later
    content: string[];
    fontSize?: number;
}

const imageUrls: { [key: number]: string } = {
    400: textBackgroundImages.Card400Img,
}

const areaTextData: { [key: string]: ResumeData } = {
    'GeorgiaText': {
        width: 400,
        fontSize: 23,
        content: [
            "Hi, I'm Giga, a tech enthusiast from Tbilisi, Georgia.",
            "Been coding websites and apps since I was 12!",
            "Welcome to my interactive resume—a snapshot of my skills and journey.",
        ]
    },
    'SanDiegoText': {
        width: 400,
        fontSize: 21,
        content: [
            "Now based in sunny San Diego, California—the perfect backdrop for tech innovation.",
            "From the beaches to the codebases, it's a city that fuels my creativity.",
            "San Diego isn't just home; it's my professional playground.",
        ]
    },
    'SDSUText': {
        width: 400,
        fontSize: 22,
        content: [
            "Earned my stripes at San Diego State University—a real game-changer for me.",
            "Made the Dean's List, but it wasn't just about grades.",
            "SDSU was my lab, my workshop, my stage—all rolled into one.",
        ]
    },
    'MitchellText': {
        width: 400,
        content: [
            "Joined Mitchell International and soon climbed up to Software Config. Engineer II.",
            "It's where I've turned code into concrete solutions that make a real-world impact.",
            "From slashing server setup times to near-perfect release processes, I've been busy.",
            "Intrigued? There's more where that came from—scroll down!"
        ]
    },
    'ProjectsText': {
        width: 400,
        content: [
            "Beyond the 9-to-5, I've been busy with projects that push the envelope.",
            "Persomedic? That's my app changing the way doctors and patients communicate.",
            "Also dipped my toes into cryptocurrency trends through academic research.",
            "Curious about my creative side? Keep scrolling!"
        ]
    },
    'AwardsText': {
        width: 400,
        content: [
            "Awards? Yeah, I've got a few under my belt.",
            "From winning hackathons to making waves in industry events, recognition has been sweet.",
            "But for me, each accolade is just a milestone on a longer journey.",
            "Ready for the full trophy case? Scroll on!"
        ]
    },
    'ContactText': {
        width: 400,
        fontSize: 21,
        content: [
            "So, you've scrolled through my journey, skills, and wins.",
            "Ready to make something amazing together?",
            "I'm just an email or LinkedIn message away.",
            "Let's connect and turn ideas into reality!"
        ]
    },
    'SoftwareEngineering': {
        width: 400,
        fontSize: 20,
        content: [
            "Expert in full-stack development, proficient in Python, Java, and C#.",
            "Proficient in advanced design patterns.",
            "Experienced in agile methodologies, test-driven development, and code reviews."
        ]
    },
    'DevOpsEngineering': {
        width: 400,
        fontSize: 22,
        content: [
            "Skilled in CI/CD pipelines using Jenkins.",
            "Proficient in containerization and orchestration with Docker and Kubernetes.",
            "Experienced in infrastructure as code using Terraform."
        ]
    },
    'CloudSolutionsArchitecture': {
        width: 400,
        fontSize: 22,
        content: [
            "Expertise in cloud platforms like AWS.",
            "Adept at designing scalable and cost-effective cloud architectures.",
            "Certified and experienced in implementing microservices and serverless patterns."
        ]
    }
};

// Fetch the imageUrl when you're using resumeDataDict
Object.keys(areaTextData).forEach((key) => {
    const data = areaTextData[key];
    data.imageUrl = imageUrls[data.width];  // Assuming `width` will always match a key in `imageUrls`
});

export default areaTextData;
