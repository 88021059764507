import React from "react";
import { css, keyframes } from "styled-components";
import { headerImages, projectsStageImages } from "../../../utils/imageResources";
import { useWindowSizeAndObjectPlacement } from "../../../utils/useWindowSize";
import SceneObject from "../../SceneObject";
import CardTextOverlay from "../../TextData/CardTextOverlay";
import areaTextData from "../../TextData/DataTextForAreas";
import SoftwareEngineeringQuest from "./SoftwareEngineeringQuest";



const CoreCompetenciesGroundLayer = React.memo(props => {

  const {yCoordinate } = useWindowSizeAndObjectPlacement();

  const header_y = yCoordinate-yCoordinate/3;

  const topDropKeyframes = props => keyframes`
  100% {left: -150px; bottom: ${header_y}px;}
`;
  const topDropAnimationCSS = css`
  ${topDropKeyframes} 3s ease-in forwards;
`;

  return (
    <SceneObject {...props} name="Core Competencies Ground Layer">


      <SceneObject
        name="BACKGROUND - Software Engineering"
        transform={{
          position: { x: 200, y: 100 },
          scale: { x: 550, y: 550 }
        }}
        $imgUrl={projectsStageImages.CodingBg}
      />
      <SceneObject
        name="MAIN ROAD"
        color="#2e3fe6"
        transform={{ position: { x: 90, y: -200 }, scale: { x: 7500, y: 300 } }}
      />

      <SceneObject
        name="TEXT - Core Competencies"
        transform={{
          position: { x: -150, y: yCoordinate-yCoordinate/4 },
          scale: { x: 550, y: 323 },
          sdposition: { x: -150, y: 550 },  // Small screen position
          sdscale: { x: 300, y: 300 } // Small screen position
        }}
        $imgUrl={headerImages.Header05}
        animation={props.scroll > 2000 ? topDropAnimationCSS : ""}
      />


      <SceneObject
        name="TEXT - Software Engineering card"
        transform={{
          position: { x: 1000, y: yCoordinate-yCoordinate/1.5 },
          scale: { x: 400, y: 400 },
          sdposition: { x: 800, y: 320 },  // Small screen position
          sdscale: { x: 300, y: 300 }, // Small screen position
          opacity: props.scroll > 25920 && props.scroll < 26220 ? 1 : 0,
          transition: "opacity 0.1s ease-in-out"
        }}>
        <CardTextOverlay
          data={areaTextData["SoftwareEngineering"]}
        />
      </SceneObject>



      <SceneObject
        name="BACKGROUND - DevOps Engineering"
        transform={{
          position: { x: 1420, y: 300 },
          scale: { x: 350, y: 550 }
        }}
        $imgUrl={projectsStageImages.DevOpsBg}
      />
      <SceneObject
        name="STEP - 02"
        transform={{
          position: { x: 1670, y: 120 },
          scale: { x: 300, y: 400 }
        }}
        $imgUrl={projectsStageImages.Step02}
      />

      <SceneObject
        name="TEXT - DevOps Engineering card"
        transform={{
          position: { x: 1800, y: yCoordinate-yCoordinate/1.5 },
          scale: { x: 400, y: 400 },
          sdposition: { x: 1600, y: 320 },  // Small screen position
          sdscale: { x: 300, y: 300 }, // Small screen position
          opacity: props.scroll > 26770 && props.scroll < 27070 ? 1 : 0,
          transition: "opacity 0.1s ease-in-out"
        }}>
        <CardTextOverlay
          data={areaTextData["DevOpsEngineering"]}
        />
      </SceneObject>



      <SceneObject
        name="BACKGROUND - Cloud Solutions Architecture"
        transform={{
          position: { x: 2200, y: 100 },
          scale: { x: 800, y: 800 }
        }}
        $imgUrl={projectsStageImages.ArchitectBg}
      />
      <SceneObject
        name="STEP - 03"
        transform={{
          position: { x: 3050, y: 120 },
          scale: { x: 380, y: 400 }
        }}
        $imgUrl={projectsStageImages.Step03}
      />
      <SceneObject
        name="TEXT - Cloud Solutions Architect card"
        transform={{
          position: { x: 3250, y: yCoordinate-yCoordinate/1.5 },
          scale: { x: 400, y: 400 },
          sdposition: { x: 3000, y: 320 },  // Small screen position
          sdscale: { x: 300, y: 300 }, // Small screen position
          opacity: props.scroll > 28180 && props.scroll < 28561 ? 1 : 0,
          transition: "opacity 0.1s ease-in-out"
        }}>
        <CardTextOverlay
          data={areaTextData["CloudSolutionsArchitecture"]}
        />
      </SceneObject>



      <SceneObject
        name="SoftwareEngineeringQuest"
        transform={{ position: { x: 3800, y: yCoordinate-yCoordinate/1.1 }, scale: { x: 500, y: 500 } }}
      >
        <SoftwareEngineeringQuest />
      </SceneObject>
      <SceneObject
        name="STEP - 01"
        transform={{
          position: { x: 860, y: 120 },
          scale: { x: 300, y: 400 }
        }}
        $imgUrl={projectsStageImages.Step01}
      />

    </SceneObject>
  );
});

export default CoreCompetenciesGroundLayer;
