import React from "react";
import SceneObject from "../../SceneObject";
import BoxComponent from "../../TextData/BoxComponent";

import PalmTree from "./PalmTrees";

const SanDiegoGroundLayer = React.memo(props => {
  return (
    <SceneObject {...props} name="SanDiego Ground Layer">
      <SceneObject
        name="SAND FOREGROUND"
        color="#f7caa3"
        transform={{ position: { x: -750, y: 0 }, scale: { x: 5800, y: 70 } }}
      />

      <SceneObject
        name="TEXT"
        transform={{
          position: { x: 1200, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={8100}
          text="The city’s blend of innovation and laid-back lifestyle is truly inspiring. "
        />
      </SceneObject>


     



      <PalmTree transform={{ position: { x: 650, y: 170 }, scale: { x: 400, y: 1000 } }} type="3" />
      <PalmTree transform={{ position: { x: 800, y: 170 }, scale: { x: 500, y: 1000 } }} type="2" />
      <PalmTree transform={{ position: { x: 950, y: 170 }, scale: { x: 400, y: 1000 } }} type="3" />

      <PalmTree transform={{ position: { x: 2500, y: 170 }, scale: { x: 500, y: 1000 } }} type="3" />
      <PalmTree transform={{ position: { x: 3000, y: 170 }, scale: { x: 400, y: 1000 } }} type="3" />
      <PalmTree transform={{ position: { x: 3200, y: 170 }, scale: { x: 400, y: 1000 } }} type="3" />

      <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 3700, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={10700}
          text="San Diego is not just where I live; it's where I thrive. "
        />
      </SceneObject>
    </SceneObject>
  );
});

export default SanDiegoGroundLayer;
