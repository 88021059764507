import React from "react";
import { workStageImages } from "../../../utils/imageResources";
import SceneObject from "../../SceneObject";


const House = React.memo(props => (
  <SceneObject
    name="House"
    transform={{
      position: props.transform.position,
      scale: props.transform.scale || { x: 350, y: 150 }
    }}
    $imgUrl={workStageImages.HouseImg}
  />
));
export default House;
