export default function GenerateBackgroundColor(scroll: number): string {
  // Initialize variable to hold the background color
  let backgroundColor = "black"; // Default color

  // Determine the background color based on the scroll position
  if (scroll >= 0 && scroll < 5050) {
    backgroundColor = "#00bff3"; // Georgia Sky Color
  } else if (scroll >= 5050 && scroll < 12900) {
    backgroundColor = "#d800d3"; // San Diego Sky Color
  } else if (scroll >= 12900 && scroll < 17650) {
    backgroundColor = "#5D8AA8"; // SDSU Sky Color
  } else if (scroll >= 17650 && scroll < 22800) {
    backgroundColor = "#00bff3"; // Work Sky Color
  } else if (scroll >= 22800 && scroll < 28150) {
    backgroundColor = "#00bff3"; // Projects Sky Color
  } else if (scroll >= 28150 && scroll < 31000) {
    backgroundColor = "#FFEB3B"; // Contact Me Sky Color
  }

  return backgroundColor;
}
