import React from "react";

import SceneObject from "../../SceneObject";

const SdsuGroundLayer = React.memo(props => {
  return (
    <SceneObject {...props} name="Sdsu Ground Layer">

      <SceneObject
        name="SAND FOREGROUND"
        color="moccasin"
        transform={{
          position: { x: 800, y: -1900 },
          scale: { x: 5000, y: 1977 }
        }}
      />

      <SceneObject
        name="MAIN ROAD LEFT RAMP"
        color="grey"
        transform={{
          position: { x: 289, y: -816 },
          scale: { x: 150, y: 1100 },
          rotation: 45
        }}
      />
      <SceneObject
        name="Sand-left-ramp"
        color="moccasin"
        transform={{
          position: { x: -120, y: -2140 },
          scale: { x: 1500, y: 1977 },
          rotation: 45
        }}
      />
      <SceneObject
        name="Sand-left-ramp-2"
        color="moccasin"
        transform={{
          position: { x: -50, y: -1130 },
          scale: { x: 500, y: 500 },
        }}
      />
      <SceneObject
        name="MAIN ROAD"
        color="grey"
        transform={{
          position: { x: 700, y: 76 },
          scale: { x: 5000, y: 100 }
        }}
      />
    </SceneObject>
  );
});

export default SdsuGroundLayer;
