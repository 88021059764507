import { textBackgroundImages } from "../../utils/imageResources";


interface ResumeData {
    width: number;
    imageUrl?: string;  // Optional, since you might compute it later
    textColor: string;
    headline: string;
    timePeriod: string;
    location: string;
    content: string[];
}

const imageUrls: { [key: number]: string } = {
    400: textBackgroundImages.Card400Img,
    600: textBackgroundImages.Billboard800Img,
}

const resumeDataDict: { [key: string]: ResumeData } = {
    'GeorgiaText': {
        width: 600,
        headline: "Software Engineer II",
        timePeriod: "Mar 2021 - Present",
        imageUrl: imageUrls[600],
        textColor: "white",
        location: "San Diego, CA",
        content: [
            "Artifact Deployment: Manage the deployment via Jenkins pipelines, achieving a 99% successful deployment rate.",
            "Python Flask Solutions: Develop a Python Flask API to automate key aspects of the release process, cutting readiness report times from hours to milliseconds.",
            "Proof of Concepts: Lead various POCs to evaluate new tools and methodologies, contributing to the adoption of improved deployment strategies and automation techniques."
        ]
    },
    'ManagerBMS': {
        width: 600,
        headline: "Manager, Scientific Data Engineering",
        timePeriod: "Dec 2023 - Present",
        imageUrl: textBackgroundImages.Billboard2_800Img,
        textColor: "#383434",
        // textColor: "white",
        location: "San Diego, CA",
        content: [
            "Overseeing informatics for 5000+ scientific instruments, enhancing research efficiency and data integrity.",
            "Refining instrument scheduling and monitoring systems, reducing downtime and boosting throughput.",
            "Driving data management innovations, including automated quality checks and metadata tagging, improving searchability and standards compliance.",
            "Leading the transition to cloud-based storage and analysis, significantly lowering storage costs and increasing data processing efficiency."
        ]
    },
    'SoftwareEngineerII': {
        width: 600,
        headline: "Software Engineer II",
        timePeriod: "Mar 2021 - Dec 2023",
        imageUrl: imageUrls[600],
        textColor: "white",
        location: "San Diego, CA",
        content: [
            "Artifact Deployment: Manage the deployment via Jenkins pipelines, achieving a 99% successful deployment rate.",
            "Python Flask Solutions: Develop a Python Flask API to automate key aspects of the release process, cutting readiness report times from hours to milliseconds.",
            "Proof of Concepts: Lead various POCs to evaluate new tools and methodologies, contributing to the adoption of improved deployment strategies and automation techniques."
        ]
    },
    'SoftwareEngineerI': {
        width: 600,
        headline: "Software Engineer I",
        timePeriod: "Aug 2019 - Mar 2021",
        imageUrl: imageUrls[600],
        textColor: "white",
        location: "San Diego, CA",
        content: [
            "Release Coordination: Work closely with build and QA teams to streamline the transition of artifacts from build to release, reducing downtime and rollback instances by 20%.",
            "Monitoring & Analytics: Implement real-time monitoring and analytics specifically for deployments using Elasticsearch and Kibana, enabling quicker diagnosis and resolution of deployment-related issues.",
            "Server Provisioning: Utilize Ansible to automate the provisioning of production and staging servers, ensuring 100% consistency and reducing manual setup errors."]
    },
    'SoftwareEngineerIntern': {
        width: 600,  // You can now provide a width
        headline: "Software Development Engineer Intern",
        timePeriod: "May 2018 - Aug 2018",
        imageUrl: imageUrls[600],
        textColor: "white",
        location: "San Diego, CA",
        content: [
            "AngularJS to React Migration: Contributed to the transition from AngularJS to React, focusing on code refactoring and UI/UX enhancements. Achieved a 20% improvement in page load time and garnered positive user feedback.",
            "Cross-Functional Collaboration: Engaged in a cross-functional scrum team to develop new features and resolve bugs using C# and Java microservices.",
            "Software Testing: Assisted with unit and integration tests to validate software functionality, playing a key role in bug identification and verification."
        ],
    }
};



// // Fetch the imageUrl when you're using resumeDataDict
// Object.keys(resumeDataDict).forEach((key) => {
//     const data = resumeDataDict[key];
//     data.imageUrl = imageUrls[data.width];  // Assuming `width` will always match a key in `imageUrls`
// });

export default resumeDataDict;
