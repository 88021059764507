import React from "react";
import { georgiaImages } from "../../../utils/imageResources";
import { useWindowSizeAndObjectPlacement } from '../../../utils/useWindowSize';
import SceneObject from "../../SceneObject";
import Tree from "./Tree";
import BoxComponent from '../../TextData/BoxComponent';
import './Georgia.css';

const GeorgiaGroundLayer = React.memo(props => {
  // This component is a SceneObject that contains the Georgia Ground Layer

  // The Georgia Ground Layer contains SceneObjects which are relatively possitioned to the ground layer
  // for example tree x position is 700, y position is 210 that means
  // the tree is 700px from the left and 210px from the top of the ground layer 

  const { width } = useWindowSizeAndObjectPlacement();


  return (
    <SceneObject {...props} name="Georgia Ground Layer">
      <SceneObject
        name="GRASS - FOREGROUND"
        color="#4bab3e"
        transform={{
          position: { x: -1500, y: -700 },
          scale: { x: 6500, y: 800 }
        }}
      />

      <SceneObject
        name="MAIN ROAD"
        color="grey"
        transform={{
          position: { x: -1500, y: 90 },
          scale: { x: 6500, y: 50 }
        }}
      />

      <SceneObject
        name="TEXT - SCROLL DOWN"
        transform={{
          position: { x: -width / 3, y: 0 },
          scale: { x: 2000, y: 100 },
          sdposition: { x: -width / 7, y: 0 },
          sdscale: { x: 1000, y: 100 }
        }}
      >
        <p class="instruction-text">Scroll to Discover!</p>
      </SceneObject>

      <Tree transform={{ position: { x: 700, y: 210 }, scale: { x: 400, y: 703 } }} type="3" />
      <Tree transform={{ position: { x: 450, y: 150 } }} type="2" />
      <Tree transform={{ position: { x: 900, y: 200 }, scale: { x: 400, y: 703 } }} type="1" />
      <Tree transform={{ position: { x: 1900, y: 210 }, scale: { x: 400, y: 703 } }} type="6" />
      <Tree transform={{ position: { x: 2000, y: 200 } }} type="4" />


      <SceneObject
        name="president-palace"
        transform={{
          position: { x: 1000, y: 200 },
          scale: { x: 1000, y: 1000 }
        }}
        $imgUrl={georgiaImages.PresidentPalaceImg}
      />

      <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 400, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={400}
          text="Welcome to my Interactive Resume!"
        />
      </SceneObject>


      <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 1000, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={1000}
          text="Let's scroll through my journey, work experiences, and learn more about me."
        />
      </SceneObject>

      <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 1500, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={1500}
          text="Oh, and most people call me Giga!"
        />
      </SceneObject>




      <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 2100, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={2100}
          text="Now let's start with where I come from, which is Tbilisi, Georgia."
        />
      </SceneObject>




      <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 3200, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={3200}
          text="My journey in technology started at a young age and has taken me across the globe."
        />
      </SceneObject>

      <SceneObject
        name="TEXT - Georgia card"
        transform={{
          position: { x: 4200, y: 150 },
          scale: { x: 400, y: 400 }
        }}>
        <BoxComponent
          scroll={props.scroll}
          start={4200}
          text="I moved to the vibrant city of San Diego, California, a hub for innovation and technology."
        />
      </SceneObject>

      <SceneObject
        name="president-palace-left-slope"
        color="grey"
        transform={{
          position: { x: 1359, y: 98 },
          scale: { x: 50, y: 100 },
          rotation: 45
        }}
      />
      <SceneObject
        name="president-palace-right-slope"
        color="grey"
        transform={{
          position: { x: 1590, y: 98 },
          scale: { x: 50, y: 100 },
          rotation: -45
        }}
      />
      <SceneObject
        name="president-palace-entrance"
        color="grey"
        transform={{
          position: { x: 1400, y: 90 },
          scale: { x: 200, y: 110 }
        }}
      />
    </SceneObject>
  );
});

export default GeorgiaGroundLayer;
