import React, { useState } from 'react';
import './SoftwareEngineeringQuest.css';

const SoftwareEngineeringQuest: React.FC = () => {
    const [input, setInput] = useState<string>('');
    const [isCorrect, setIsCorrect] = useState<boolean | null>(null);

    const normalizeString = (str: string) => {
        return str.trim().replace(/\s+/g, ' ').replace(/['"]/g, "'").replace(/;$/, '');
    };

    const checkAnswer = () => {
        // Normalize input: Remove leading/trailing white spaces and extra spaces between characters
        const normalizedInput = normalizeString(input);

        // List of possible correct answers
        const rawCorrectAnswers = [
            "split('').reverse().join('')",
            "split('').reverse().join(\"\")",
            'split(\'\').reverse().join("")',
            'split(\'\').reverse().join(\'\')',
            'split("").reduce((rev, char) => char + rev, "")',
            'split(\'\').reduce((rev, char) => char + rev, \'\')',
            'Array.from(str).reverse().join("")',
            'Array.from(str).reverse().join(\'\')'
        ];

        // Normalize correct answers
        const correctAnswers = rawCorrectAnswers.map(normalizeString);

        // Check if the normalized input matches any of the correct answers
        if (correctAnswers.includes(normalizedInput)) {
            setIsCorrect(true);
        } else {
            setIsCorrect(false);
        }
    };


    return (
        <div className="quest-container">

            <h1 style={{ color: "#0060d3" }}>Software Engineering Quest</h1>
            <p style={{ color: "#0060d3" }}>Complete the following JavaScript function to reverse a string:</p>
            <pre>
                <code>
                    <div className="inline-code-container">
                        {`function reverseString(str) {`}
                    </div>
                    <div className="inline-code-container">
                        <span>{`  return str.`}</span>
                        <input
                            className="inline-code-input"
                            type="text"
                            placeholder="Fill in the missing code"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                        />
                    </div>
                    <div className="inline-code-container">
                        {`}`}
                    </div>
                </code>
            </pre>
            <button className="quest-button" onClick={checkAnswer} style={{ color: "#ffffff" }}>Submit</button>
            {isCorrect === true && <p className="quest-success-message">Congratulations, you've completed the quest!</p>}
            {isCorrect === false && <p>Try Again</p>}

        </div>
    );
};

export default SoftwareEngineeringQuest;
