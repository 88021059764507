import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { textures } from "../../utils/imageResources";

const Box = styled.div`
  position: relative;
  display: inline-block;
  padding: 20px;
  border: 5px solid transparent;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5);
  background: rgba(0, 191, 255, 0.8); /* Sky blue background */
  color: #FFFAFA; /* Snow white text color */
  font-family: 'Quicksand', sans-serif; /* Use the custom font */
  font-size: 1.2rem;
  font-weight: bold; /* Make the text bold */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add text shadow for better readability */
  margin: 20px 0;
  border-radius: 15px;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(50px);
  background-image: url('${textures.Diagmonds}'); /* Adding texture */

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Frame = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 20px;
  pointer-events: none;
`;

const BoxComponent = ({ scroll, start, text }) => {
  const boxRef = useRef(null);
//   console.log('box scroll', scroll);

  useEffect(() => {
    const element = boxRef.current;

    const handleScroll = () => {
      const rect = element.getBoundingClientRect();

      const end = rect.width + start;

      if (scroll <= end && scroll >= start-200) {
        gsap.to(element, {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.out',
        });
      } else {
        gsap.to(element, {
          opacity: 0,
          y: 50,
          duration: 1,
          ease: 'power3.out',
        });
      }
    };

    handleScroll(); // Initial check
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scroll, start]);

  return (
    <Box ref={boxRef}>
      <Frame />
      {text}
    </Box>
  );
};

export default BoxComponent;
