import React, { Component } from "react";
import FloatedLayer from "./FloatedLayer";
import GeorgiaEnvironmentLayer from "../Stages/Georgia/GeorgiaEnvironmentLayer";
import SanDiegoEnvironmentLayer from "../Stages/SanDiego/SanDiegoEnvironmentLayer";
import SdsuEnvironmentLayer from "../Stages/SDSU/SdsuEnvironmentLayer";

import AnimateCalculation from "../../utils/AnimateCalculation";
import WorkEnvironmentLayer from "../Stages/Work/WorkEnvironmentLayer";

// The Environment layer is the second layer. In between Ground and Sky

const stages = [
  {
    name: 'Georgia Area',
    startScroll: 0,
    endScroll: 5050,
    startPosition: { x: 0, y: 0 },
    endPosition: { x: -4000, y: 0 }
  },
  {
    name: 'Georgia Downhill',
    startScroll: 5050,
    endScroll: 7050,
    startPosition: { x: -4000, y: 0 },
    endPosition: { x: -4800, y: 700 }
  },
  {
    name: 'San Diego Area',
    startScroll: 7050,
    endScroll: 12100,
    startPosition: { x: -4800, y: 700 },
    endPosition: { x: -8750, y: 700 }
  },
  {
    name: 'San Diego Uphill',
    startScroll: 12100,
    endScroll: 13100,
    startPosition: { x: -8750, y: 700 },
    endPosition: { x: -9700, y: 0 }
  },
  {
    name: 'SDSU Area',
    startScroll: 13100,
    endScroll: 17650,
    startPosition: { x: -9700, y: 0 },
    endPosition: { x: -13000, y: 0 }
  },
  {
    name: 'SDSU Uphill',
    startScroll: 17650,
    endScroll: 18650,
    startPosition: { x: -13000, y: 0 },
    endPosition: { x: -14200, y: -300 }
  },
  {
    name: 'Work Area',
    startScroll: 18650,
    endScroll: 24050, // Adjusted from 22050 to 24050
    startPosition: { x: -14200, y: -300 },
    endPosition: { x: -23000, y: -300 }
  },
  {
    name: 'Work Downhill',
    startScroll: 24050, // Adjusted from 22050
    endScroll: 25050, // Adjusted from 23050
    startPosition: { x: -23000, y: -300 },
    endPosition: { x: -23500, y: 0 }
  },
  {
    name: 'Projects Area',
    startScroll: 25050, // Adjusted from 23050
    endScroll: 30150, // Adjusted from 28150
    startPosition: { x: -23500, y: 0 },
    endPosition: { x: -25650, y: 0 }
  },
  {
    name: 'Contact Me',
    startScroll: 30150, // Adjusted from 28150
    endScroll: 32000, // Adjusted from 30000
    startPosition: { x: -25650, y: 0 },
    endPosition: { x: -31000, y: -1100 }
  }
];


export class Environment extends Component {
  constructor(props) {
    super(props);
    this.transform = { position: { x: 0, y: 0 } };

    // Convert stages to animations
    this.animations = stages.reduce((acc, stage) => {
      const key = `${stage.startScroll}-${stage.endScroll}`;
      acc[key] = {
        position: {
          x: [stage.startPosition.x, stage.endPosition.x],
          y: [stage.startPosition.y, stage.endPosition.y],
        },
      };
      return acc;
    }, {});
  }

  // Additional Function to identify the current stage
  getCurrentStage(scrollPosition) {
    const currentStage = stages.find(stage => stage.startScroll <= scrollPosition && stage.endScroll >= scrollPosition);
    return currentStage || { name: 'Unknown Stage' };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.scroll !== nextProps.scroll) {
      this.transform = AnimateCalculation(this.animations, nextProps.scroll);

      // const currentStage = this.getCurrentStage(nextProps.scroll);
      // console.log(`[LOG - ENV] Current Stage: ${currentStage.name}, X: ${this.transform.position.x}, Y: ${this.transform.position.y}`);

      return true;
    }
    return false;
  }

  render() {
    return (
      <FloatedLayer name="ENVIRONMENT" transform={this.transform}>
        <GeorgiaEnvironmentLayer
          {...this.props}
          transform={{ position: { x: 0, y: 0 } }}
        />
        <SanDiegoEnvironmentLayer
          {...this.props}
          transform={{ position: { x: 4800, y: -700 } }}
        />
        <SdsuEnvironmentLayer
          {...this.props}
          transform={{ position: { x: 9700, y: 0 } }}
        />
        <WorkEnvironmentLayer
          {...this.props}
          transform={{ position: { x: 14600, y: 300 } }}
        />
      </FloatedLayer>
    );
  }
}

export default React.memo(Environment);