import { Component } from "react";
import FloatedLayer from "./FloatedLayer";

import GeorgiaSkyLayer from "../Stages/Georgia/GeorgiaSkyLayer";
import SanDiegoSkyLayer from "../Stages/SanDiego/SanDiegoSkyLayer";
import SdsuSkyLayer from "../Stages/SDSU/SdsuSkyLayer";

import AnimateCalculation from "../../utils/AnimateCalculation";

// The Sky layer is the third layer. Behind the Ground and Environment

const stages = [
    {
        name: 'Georgia Start',
        startScroll: 0,
        endScroll: 1050,
        startPosition: { x: 0, y: 0 },
        endPosition: { x: -700, y: 0 }
    },
    {
        name: 'Georgia Area',
        startScroll: 1050,
        endScroll: 5050,
        startPosition: { x: -700, y: 0 },
        endPosition: { x: -3000, y: 0 }
    },
    {
        name: 'Georgia Downhill',
        startScroll: 5050,
        endScroll: 7050,
        startPosition: { x: -3000, y: 0 },
        endPosition: { x: -4000, y: 700 }
    },
    {
        name: 'San Diego Area',
        startScroll: 7050,
        endScroll: 12100,
        startPosition: { x: -4000, y: 700 },
        endPosition: { x: -6700, y: 700 }
    },
    {
        name: 'San Diego Uphill',
        startScroll: 12100,
        endScroll: 13600,
        startPosition: { x: -6700, y: 700 },
        endPosition: { x: -9000, y: 0 }
    },
    {
        name: 'SDSU Area',
        startScroll: 13600,
        endScroll: 17650,
        startPosition: { x: -9000, y: 0 },
        endPosition: { x: -9000, y: 0 }
    },
    {
        name: 'SDSU Uphill',
        startScroll: 17650,
        endScroll: 18650,
        startPosition: { x: -9000, y: 0 },
        endPosition: { x: -9500, y: -300 }
    },
    {
        name: 'Work Area',
        startScroll: 18650,
        endScroll: 24050, // Updated from 22050 to accommodate the length extension
        startPosition: { x: -9500, y: -300 },
        endPosition: { x: -11000, y: -300 }
    },
    {
        name: 'Work Downhill',
        startScroll: 24050, // Adjusted to follow the new endScroll of Work Area
        endScroll: 25050, // Also adjusted by +2000
        startPosition: { x: -11000, y: -300 },
        endPosition: { x: -12400, y: 0 }
    },
    {
        name: 'Projects Area',
        startScroll: 25050, // Corrected and adjusted to start after Work Downhill
        endScroll: 29150, // Adjusted by +2000
        startPosition: { x: -12400, y: 0 },
        endPosition: { x: -15000, y: 0 }
    },
    {
        name: 'Contact Me',
        startScroll: 29150, // Adjusted by +2000
        endScroll: 35050, // Adjusted by +2000
        startPosition: { x: -15000, y: 0 },
        endPosition: { x: -18000, y: 0 }
    }
];

const stagesDict = stages.reduce((acc, stage) => {
    acc[stage.name] = stage;
    return acc;
}, {});

export class Sky extends Component {
    constructor(props) {
        super(props);
        this.transform = { position: { x: 0, y: 0 } };

        // Convert stages to animations
        this.animations = stages.reduce((acc, stage) => {
            const key = `${stage.startScroll}-${stage.endScroll}`;
            acc[key] = {
                position: {
                    x: [stage.startPosition.x, stage.endPosition.x],
                    y: [stage.startPosition.y, stage.endPosition.y],
                },
            };
            return acc;
        }, {});
    }

    // Additional Function to identify the current stage
    getCurrentStage(scrollPosition) {
        const currentStage = stages.find(stage => stage.startScroll <= scrollPosition && stage.endScroll >= scrollPosition);
        return currentStage || { name: 'Unknown Stage' };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.scroll !== nextProps.scroll) {
            this.transform = AnimateCalculation(this.animations, nextProps.scroll);

            // const currentStage = this.getCurrentStage(nextProps.scroll);
            // console.log(`[LOG - SKY] Current Stage: ${currentStage.name}, X: ${this.transform.position.x}, Y: ${this.transform.position.y}`);

            return true;
        }
        return false;
    }

    render() {
        return (
            <FloatedLayer name="Sky Layer" transform={this.transform}>
                <GeorgiaSkyLayer
                    {...this.props}
                    transform={{ position: { x: stagesDict['Georgia Start'].startPosition.x, y: 0 } }}
                />
                <SanDiegoSkyLayer
                    {...this.props}
                    transform={{ position: { x: -stagesDict['San Diego Area'].startPosition.x, y: -700 } }}
                />
                <SdsuSkyLayer
                    {...this.props}
                    transform={{ position: { x: 7000, y: 1100 } }}
                />
            </FloatedLayer>
        );
    }
}

export default Sky;