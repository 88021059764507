import React from "react";

import SceneObject from "../../SceneObject";

const WorkGroundLayer = React.memo((props) => {

  return (
    <SceneObject {...props} name="Work Ground Layer">

      <SceneObject
        name="asphalt-left-ramp"
        color="grey"
        transform={{
          position: { x: -284, y: -332 },
          scale: { x: 110, y: 600 },
          rotation: 59
        }}
      />
      <SceneObject
        name="Grass-left-ramp"
        color="moccasin"
        transform={{
          position: { x: -450, y: -761 },
          scale: { x: 500, y: 800 },
          rotation: 59
        }}
      />
      <SceneObject
        name="MAIN ROAD"
        color="grey"
        transform={{ position: { x: 0, y: 0 }, scale: { x: 10050, y: 170 } }}
      />
      <SceneObject
        name="GRASS - FOREGROUND"
        color="green"
        transform={{ position: { x: 0, y: -500 }, scale: { x: 10100, y: 550 } }}
      />
      <SceneObject
        name="GRASS - FOREGROUND right-ramp"
        color="green"
        transform={{
          position: { x: 9900, y: -644 },
          scale: { x: 600, y: 600 },
          rotation: 307
        }}
      />
      <SceneObject
        name="MAIN ROAD right-ramp"
        color="grey"
        transform={{
          position: { x: 10280, y: -515 },
          scale: { x: 110, y: 800 },
          rotation: 307
        }}
      />
    </SceneObject>
  );
});

export default WorkGroundLayer;
