import React from "react";

import SceneObject from "../../SceneObject";

import { georgiaImages } from "../../../utils/imageResources";


const trees = georgiaImages.Trees;

const Tree = React.memo(props => {
  return (
    <SceneObject
      name="Tree"
      transform={{
        position: props.transform.position,
        scale: props.transform.scale
          ? props.transform.scale
          : { x: 500, y: 603 },
        rotation: props.transform.rotation,
        opacity: 1
      }}
      $imgUrl={props.type ? trees[props.type - 1] : trees[0]}
    />
  );
});

export default Tree;
