import React from "react";
import { workStageImages } from "../../../utils/imageResources";
import SceneObject from "../../SceneObject";


const WorkBuilding = React.memo(props => (
  <SceneObject
    name="Work Building - Mitchell International"
    transform={{
      position: props.transform.position,
      scale: props.transform.scale || { x: 350, y: 150 }
    }}
    $imgUrl={workStageImages.WorkBuildingImg}
  />
));
export default WorkBuilding;
