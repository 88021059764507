import React from 'react';
import styled from 'styled-components';

interface ImageWithTextProps {
  data: any;
}

interface ColorProps {
  color?: string;
}

const ImageContainer = styled.div<{ width: number }>`
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
`;

const OverlayImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Headline = styled.h2<ColorProps>`
  position: absolute;
  top: 40px;
  left: 0px;
  color: ${(props) => props.color || 'white'};
`;

const TimePeriod = styled.span<ColorProps>`
  position: absolute;
  top: 110px;
  left: 0px;
  width: 250px;
  height: 50px;
  color: ${(props) => props.color || 'white'};
  font-weight: bold;
`;

const Location = styled.span<ColorProps>`
  position: absolute;
  top: 110px;
  left: 300px;
  width: 150px;
  height: 50px;
  color: ${(props) => props.color || 'white'};
  font-weight: bold;
`;

const Content = styled.ul<{ width: number; color?: string  }>`
  position: absolute;
  top: 135px;
  left: 0px;
  width: ${(props) => props.width - 60}px;
  color: ${(props) => props.color || 'white'};
  list-style: disc;
  padding-left: 0;
  font-size: 14px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const BillboardWithText: React.FC<ImageWithTextProps> = ({ data }) => {
  const { width, imageUrl, headline, timePeriod, location, content, textColor} = data;

  return (
    <ImageContainer width={width}>
      <OverlayImage src={imageUrl} alt={headline} />
      <TextOverlay>
        <Headline color={textColor}>{headline}</Headline>
        <TimePeriod color={textColor}>{timePeriod}</TimePeriod>
        <Location color={textColor}>{location}</Location>
        <Content width={width} color={textColor}>
          {content.map((sentence: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, index: React.Key | null | undefined) => (
            <ListItem key={index}>{sentence}</ListItem>
          ))}
        </Content>
      </TextOverlay>
    </ImageContainer>
  );
};

export default BillboardWithText;
