

import React, { Component } from 'react';
import FloatedLayer from './FloatedLayer';

import ContactMeGroundLayer from '../Stages/ContactMe/ContactMeGroundLayer';
import GeorgiaGroundLayer from '../Stages/Georgia/GeorgiaGroundLayer';
import CoreCompetenciesGroundLayer from '../Stages/CoreCompetencies/CoreCompetenciesGroundLayer';
import SanDiegoGroundLayer from '../Stages/SanDiego/SanDiegoGroundLayer';
import SdsuGroundLayer from '../Stages/SDSU/SdsuGroundLayer';
import WorkGroundLayer from '../Stages/Work/WorkGroundLayer';

import AnimateCalculation from '../../utils/AnimateCalculation';
import SceneObject from '../SceneObject';

// The Ground layer is the first layer

const stages = [
    {
        //This will swipe ground layer -5050px on x axis at 0-5050 scroll range
        name: 'Georgia Area',
        startScroll: 0,
        endScroll: 5050,
        startPosition: { x: 0, y: 0 },
        endPosition: { x: -5050, y: 0 }
    },
    {
        name: 'Georgia Downhill',
        startScroll: 5050,
        endScroll: 7050,
        startPosition: { x: -5050, y: 0 },
        endPosition: { x: -5750, y: 700 }
    },
    {
        name: 'San Diego Area',
        startScroll: 7050,
        endScroll: 12100,
        startPosition: { x: -5750, y: 700 },
        endPosition: { x: -10800, y: 700 }
    },
    {
        name: 'San Diego Uphill',
        startScroll: 12100,
        endScroll: 13100,
        startPosition: { x: -10800, y: 700 },
        endPosition: { x: -11500, y: 0 }
    },
    {
        name: 'SDSU Area',
        startScroll: 13100,
        endScroll: 17650,
        startPosition: { x: -11500, y: 0 },
        endPosition: { x: -15850, y: 0 }
    },
    {
        name: 'SDSU Uphill',
        startScroll: 17650,
        endScroll: 18650,
        startPosition: { x: -15850, y: 0 },
        endPosition: { x: -16350, y: -300 }
    },
    {
        name: 'Work Area',
        startScroll: 18650,
        endScroll: 24050,
        startPosition: { x: -16350, y: -300 },
        endPosition: { x: -26400, y: -300 }
    },
    {
        name: 'Work Downhill',
        startScroll: 24050,
        endScroll: 25050,
        startPosition: { x: -26400, y: -300 }, // Now starts at -26400, matching the end of Work Area
        endPosition: { x: -26800, y: 0 } // Adjusted for continuity, though this might need tweaking based on your visual needs
    },
    {
        name: 'Projects Area',
        startScroll: 25050,
        endScroll: 30150,
        startPosition: { x: -26800, y: 0 }, // Now starts at -26800, matching the end of Work Downhill
        endPosition: { x: -31800, y: 0 } // Adjusted to ensure a logical progression
    },
    {
        name: 'Contact Me',
        startScroll: 30150,
        endScroll: 32000,
        startPosition: { x: -31800, y: 0 }, // Now starts at -31800, matching the end of Projects Area
        endPosition: { x: -34800, y: 0 } // Further extended to utilize the additional space
    }    
];

const stagesDict = stages.reduce((acc, stage) => {
    acc[stage.name] = stage;
    return acc;
}, {});


export class Ground extends Component {
    constructor(props) {
        super(props);
        this.transform = { position: { x: 0, y: 0 } };

        // Convert stages to animations
        this.animations = stages.reduce((acc, stage) => {
            const key = `${stage.startScroll}-${stage.endScroll}`;
            acc[key] = {
                position: {
                    x: [stage.startPosition.x, stage.endPosition.x],
                    y: [stage.startPosition.y, stage.endPosition.y],
                },
            };
            return acc;
        }, {});
    }

    // Additional Function to identify the current stage
    getCurrentStage(scrollPosition) {
        const currentStage = stages.find(stage => stage.startScroll <= scrollPosition && stage.endScroll >= scrollPosition);
        return currentStage || { name: 'Unknown Stage' };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.scroll !== nextProps.scroll) {
            this.transform = AnimateCalculation(this.animations, nextProps.scroll);

            // const currentStage = this.getCurrentStage(nextProps.scroll);
            // console.log(`[LOG - GND] Current Stage: ${currentStage.name}, X: ${this.transform.position.x}, Y: ${this.transform.position.y}`);

            return true;
        }
        return false;
    }


    render() {
        return (
            <FloatedLayer name="GROUND" transform={this.transform} zindex={30}>
                <SceneObject
                    name="MiddleLayer"
                    color="grey"
                    transform={{
                        position: { x: 4557, y: -932 },
                        scale: { x: 1100, y: 800 },
                        rotation: 45
                    }}
                />
                <GeorgiaGroundLayer
                    {...this.props}
                    transform={{ position: { x: 0, y: 0 } }}
                />
                <SanDiegoGroundLayer
                    {...this.props}
                    transform={{ position: { x: -stagesDict['San Diego Area'].startPosition.x, y: -700 } }}
                />
                <SdsuGroundLayer
                    {...this.props}
                    transform={{ position: { x: -stagesDict['San Diego Uphill'].startPosition.x, y: 0 } }}
                />
                <WorkGroundLayer
                    {...this.props}
                    transform={{ position: { x: -stagesDict['Work Area'].startPosition.x, y: 300 } }}
                />
                <CoreCompetenciesGroundLayer
                    {...this.props}
                    transform={{ position: { x: -stagesDict['Projects Area'].startPosition.x, y: 0 } }}
                />
                <ContactMeGroundLayer
                    {...this.props}
                    transform={{ position: { x: -stagesDict['Contact Me'].startPosition.x, y: 0 } }}
                />
            </FloatedLayer>
        );
    }
}

export default React.memo(Ground);
