import { useWindowSizeAndObjectPlacement } from "../../utils/useWindowSize";
import { playerImages } from "../../utils/imageResources";

const SMALL_SCREEN_THRESHOLD = 910;

export const usePlayers = () => {
    
    const windowWidth = useWindowSizeAndObjectPlacement().width;
    const isSmallScreen = windowWidth <= SMALL_SCREEN_THRESHOLD;
  
    // Define players
    const players = {
      space: {
        speed: 30,
        frames: [
          ["", "", ""], //backward
          ["", "", ""], //forward
        ],
      },
      giga: {
        // speed: 80,
        // delimiter: 10, // speed / step
        speed: isSmallScreen ? 6 : 16,
        scale: { x: 250, y: 400 },
        frames: [
          [...playerImages.gigal], // backward
          [...playerImages.gigar], // forward
        ],
      },
      tesla: {
        speed: isSmallScreen ? 2 : 4,
        scale: isSmallScreen ? { x: 550, y: 400 } : { x: 600, y: 400 } ,
        frames: [
          [...playerImages.tesla_l], // backward
          [...playerImages.tesla_r.reverse()], // forward
        ],
      },
    };
  
    return players;
  };