import React from "react";
import { sdsuStageImages } from "../../../utils/imageResources";

import SceneObject from "../../SceneObject";


const SdsuSkyLayer = React.memo(props => {
  return (
    <SceneObject {...props} name="Sdsu Sky Layer">
      <SceneObject
        name="Sun"
        transform={{
          position: { x: 0, y: -2000 },
          scale: { x: 4000, y: 4000 },
          opacity: 0.3
        }}
        $imgUrl={sdsuStageImages.SunImg}
      />
    </SceneObject>
  );
});

export default SdsuSkyLayer;
