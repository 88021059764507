import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
    title: string;
    description: string;
    keywords: string[];
}

const SEO: React.FC<SEOProps> = ({ title, description, keywords }) => {
    return (
        <Helmet>
            {/* Basic meta tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords.join(', ')} />
            
            {/* Open Graph meta tags */}
            <meta property="og:title" content="Grigoli (Giga) Vashakidze - Interactive Resume" />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="%PUBLIC_URL%/thumbnail.jpg" />
            <meta property="og:url" content="https://www.vashakidze.com/" />
        </Helmet>
    );
};

export default SEO;
