// Georgia Images
import PresidentPalaceImg from "../assets/images/president_palace/PresidentPalace.png";
import SamebaImg from "../assets/images/sameba/sameba.png";
import FreedomBridgeImg from "../assets/images/tbilisi/freedom_bridge.png";
import FreedomSquareImg from "../assets/images/tbilisi/freedom_square.png";
import MtatsmindaImg from "../assets/images/tbilisi/mtatsminda.png";
import Skyline from "../assets/images/tbilisi/tbilisi_skyline.png";
import Tree1 from "../assets/images/trees/1.png";
import Tree2 from "../assets/images/trees/2.png";
import Tree3 from "../assets/images/trees/3.png";
import Tree4 from "../assets/images/trees/4.png";
import Tree5 from "../assets/images/trees/5.png";
import Tree6 from "../assets/images/trees/6.png";

// Player Images
import gigal_01 from "../assets/images/giga/gigal-01.png";
import gigal_02 from "../assets/images/giga/gigal-02.png";
import gigal_03 from "../assets/images/giga/gigal-03.png";
import gigal_04 from "../assets/images/giga/gigal-04.png";
import gigal_05 from "../assets/images/giga/gigal-05.png";
import gigal_06 from "../assets/images/giga/gigal-06.png";
import gigal_07 from "../assets/images/giga/gigal-07.png";
import gigal_08 from "../assets/images/giga/gigal-08.png";

import gigar_01 from "../assets/images/giga/gigar-01.png";
import gigar_02 from "../assets/images/giga/gigar-02.png";
import gigar_03 from "../assets/images/giga/gigar-03.png";
import gigar_04 from "../assets/images/giga/gigar-04.png";
import gigar_05 from "../assets/images/giga/gigar-05.png";
import gigar_06 from "../assets/images/giga/gigar-06.png";
import gigar_07 from "../assets/images/giga/gigar-07.png";
import gigar_08 from "../assets/images/giga/gigar-08.png";

import tesla_r1 from "../assets/images/tesla/tesla_r-01.png";
import tesla_r2 from "../assets/images/tesla/tesla_r-02.png";
import tesla_r3 from "../assets/images/tesla/tesla_r-03.png";
import tesla_r4 from "../assets/images/tesla/tesla_r-04.png";
import tesla_r5 from "../assets/images/tesla/tesla_r-05.png";
import tesla_r6 from "../assets/images/tesla/tesla_r-06.png";

import tesla_l1 from "../assets/images/tesla/tesla_l-01.png";
import tesla_l2 from "../assets/images/tesla/tesla_l-02.png";
import tesla_l3 from "../assets/images/tesla/tesla_l-03.png";
import tesla_l4 from "../assets/images/tesla/tesla_l-04.png";
import tesla_l5 from "../assets/images/tesla/tesla_l-05.png";
import tesla_l6 from "../assets/images/tesla/tesla_l-06.png";


// San Diego Images
import CaBg from "../assets/images/ca_beach/ca_bg.png";
import SandImg from "../assets/images/ca_beach/sand.png";
import LifeguardTowerImg from "../assets/images/ca_beach/sd_lifeguard.png";
import SdSignImg from "../assets/images/ca_beach/sd_sign.png";
import SurfBoardsImg from "../assets/images/ca_beach/surf_bords.png";
import StarAndClouds from "../assets/images/ca_beach/star_and_clouds.png";
import PalmTree1 from "../assets/images/palm_trees/1.png";
import PalmTree2 from "../assets/images/palm_trees/2.png";
import PalmTree3 from "../assets/images/palm_trees/3.png";
import Redstone from "../assets/images/redstones/4.png";
import Stone from "../assets/images/redstones/stone.png";

// Images for SDSU Stage
import SunImg from "../assets/images/ca_beach/ca_bg.png";
import AztecUnionImg from "../assets/images/sdsu/aztec_union.png";
import DimplomaImg from "../assets/images/sdsu/diploma.png";
import HepnerHallImg from "../assets/images/sdsu/hepner_hall.png";
import SdsuSignImg from "../assets/images/sdsu/sdsu_sign.png";

// Images for Work Stage
import HouseImg from "../assets/images/house/house.png";
import WorkBuildingImg from "../assets/images/work/mitchell.png";
import BMSWorkBuildingImg from "../assets/images/work/bms_building.png";

// Header Images
import Header02 from "../assets/images/headers/header-02.png";
import Header03 from "../assets/images/headers/header-03.png";
import Header04 from "../assets/images/headers/header-04.png";
import Header05 from "../assets/images/headers/header-05.png";
import Header06 from "../assets/images/headers/header-09.png";

import Diagmonds from "../assets/images/textrure/diagmonds.png";

// Text Background Images
import Billboard800Img from '../assets/images/resume/billboard-01.png';
import Billboard2_800Img from '../assets/images/resume/billboard-02.png';

import Card400Img from '../assets/images/resume/card.png';

// Core Competencies Stage
import ArchitectBg from '../assets/images/projects/architect_bg.png';
import CodingBg from '../assets/images/projects/coding_bg.png';
import DevOpsBg from '../assets/images/projects/devops_bg.png';
import Step01 from '../assets/images/projects/step-01.png';
import Step02 from '../assets/images/projects/step-02.png';
import Step03 from '../assets/images/projects/step-03.png';

// Contact Me Stage
import LILogo from '../assets/images/contact/LI-Logo.png';

export const georgiaImages = {
    FreedomBridgeImg,
    FreedomSquareImg,
    MtatsmindaImg,
    PresidentPalaceImg,
    SamebaImg,
    Skyline,
    Trees: [Tree1, Tree2, Tree3, Tree4, Tree5, Tree6]
};

export const textBackgroundImages = {
    Card400Img,
    Billboard800Img,
    Billboard2_800Img
};

export const playerImages = {
    gigal: [gigal_01, gigal_02, gigal_03, gigal_04, gigal_05, gigal_06, gigal_07, gigal_08],
    gigar: [gigar_08, gigar_07, gigar_06, gigar_05, gigar_04, gigar_03, gigar_02, gigar_01],
    tesla_r: [tesla_r1, tesla_r2, tesla_r3, tesla_r4, tesla_r5, tesla_r6],
    tesla_l: [tesla_l6, tesla_l5, tesla_l4, tesla_l3, tesla_l2, tesla_l1],
};

export const sanDiegoImages = {
    Redstone,
    LifeguardTowerImg,
    PalmTrees: [PalmTree1, PalmTree2, PalmTree3],
    Stone,
    SandImg,
    StarAndClouds,
    SdSignImg,
    SurfBoardsImg,
    CaBg
};

export const headerImages = {
    Header02,
    Header03,
    Header04,
    Header05,
    Header06
};

export const textures = {
    Diagmonds
}

export const sdsuStageImages = {
    AztecUnionImg,
    DimplomaImg,
    HepnerHallImg,
    SdsuSignImg,
    SunImg
};

export const workStageImages = {
    HouseImg,
    WorkBuildingImg,
    BMSWorkBuildingImg
};

export const projectsStageImages = {
    CodingBg,
    DevOpsBg,
    ArchitectBg,
    Step01,
    Step02,
    Step03
}

export const contactStageImages = {
    LILogo
}



export const allImagesGrouped = {
    georgia: georgiaImages,
    player: playerImages,
    sanDiego: sanDiegoImages,
    sdsuStage: sdsuStageImages,
    workStage: workStageImages,
    header: headerImages,
    projectStage: projectsStageImages,
    contactStage: contactStageImages,
};


// Function to flatten the images object into an array
const flattenImagesObject = (imagesObj: any): string[] => {
    let flatImages: string[] = [];

    Object.keys(imagesObj).forEach((key) => {
        if (Array.isArray(imagesObj[key])) {
            flatImages = flatImages.concat(imagesObj[key]);
        } else if (typeof imagesObj[key] === 'object') {
            flatImages = flatImages.concat(flattenImagesObject(imagesObj[key]));
        } else {
            flatImages.push(imagesObj[key]);
        }
    });

    return flatImages;
};

export const allImages = flattenImagesObject(allImagesGrouped);