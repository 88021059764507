import React from "react";
import { sanDiegoImages } from "../../../utils/imageResources";

import SceneObject from "../../SceneObject";


const PalmTrees = sanDiegoImages.PalmTrees;

const PalmTree = React.memo(props => {
    return (
        <SceneObject
            name="PalmTree"
            transform={{
                position: props.transform.position,
                scale: props.transform.scale
                    ? props.transform.scale
                    : { x: 500, y: 603 },
                rotation: props.transform.rotation,
                opacity: 1
            }}
            $imgUrl={props.type ? PalmTrees[props.type - 1] : PalmTrees[0]}
        />
    );
});

export default PalmTree;
