/**
 *
 * @param {*} animations - A dictionary containing animation data.
 *                         Example: { "0-100": { position: { x: [0, 100], y: [0, 100] } } }
 * @param {*} scroll - Current scroll position.
 *                     Example: 50
 * @param {*} speed - Optional speed multiplier, defaults to 1.0.
 *                    Example: 1.2
 */
export default function AnimateCalculation(animations, scroll, speed = 1.0) {
  // Initialize current frame to the scroll value
  let currFrame = scroll;

  // Variables to hold the current animation and the delta time
  let currAnim = null;
  let deltaTime = 0;

  // Variable to hold the range of frames for each animation
  let frameRange = null;

  // Iterate over each key in animations to find the current animation
  Object.keys(animations).some(k => {
    // Split the key to get the first and last frame (e.g., "0-100" becomes [0, 100])
    frameRange = k.split("-");

    // Check if the current frame is within this animation's frame range
    if (frameRange[0] <= currFrame && frameRange[1] > currFrame) {
      // Set the current animation and delta time
      currAnim = animations[k]; // current animation data
      deltaTime = frameRange[1] - frameRange[0]; // lastFrame - firstFrame
      currFrame -= frameRange[0];
      return true;
    }
    return false;
  });

  // Null check for currAnim
  if (!currAnim || !currAnim.position) {
    // console.warn('No animation data found for current frame.');
    return {
      position: { x: 0, y: 0 } // default position or you can return something else
    };
  }

  // Calculate the position for this frame and return it
  return {
    position: {
      x:
        currFrame *
        ((currAnim.position.x[1] - currAnim.position.x[0]) / deltaTime) *
        speed +
        currAnim.position.x[0],
      y:
        currFrame *
        ((currAnim.position.y[1] - currAnim.position.y[0]) / deltaTime) *
        speed +
        currAnim.position.y[0]
    }
  };
}
