import { css, keyframes } from "styled-components";

const animations = {
  // "0-1": { startHeight: 3000, endHeight: 3000, type: "jump" },
  // "1-30000": { startHeight: 3000, endHeight: 100, type: "fall" },
  // "23700-24470": { startHeight: 300, endHeight: 100, type: "jump" },
    "0-25920": { startHeight: 200, endHeight: 100, type: "fall" },
  "25920-26220": { startHeight: 100, endHeight: 200, type: "jump" }, // 300
  "26220-26770": { startHeight: 200, endHeight: 100, type: "fall" }, // 430
  "26770-27070": { startHeight: 100, endHeight: 200, type: "jump" }, // 300
  
  "26770-28180": { startHeight: 200, endHeight: 100, type: "fall" }, // 935
  "28180-28561": { startHeight: 100, endHeight: 200, type: "jump" }, // 381

    "28561-30500": { startHeight: 200, endHeight: 100, type: "fall" }, // 854

  // "25170-26000": { startHeight: 300, endHeight: 100, type: "fall" },
  // "28100-28850": { startHeight: 300, endHeight: 100, type: "fall" },
  // "28900-29150": { startHeight: 100, endHeight: 10000, type: "jump" },
  // "29150-30000": { startHeight: 300, endHeight: 100, type: "fall" },
};

let lastAnimType = "fall";
let lastKeyframes = [100, 0, 100];

const jump = (props) => keyframes`
  0%  {bottom: ${lastKeyframes[0] + "px"}}
  35%  {bottom: ${lastKeyframes[1] + "px"}}
  100% {bottom: ${lastKeyframes[2] + "px"}}
`;

// const freeFall = (props) => keyframes`
//   0%  {bottom: ${lastKeyframes[0] + "px"}}
//   100% {bottom: ${lastKeyframes[2] + "px"}}
// `;

const fall = (props) => keyframes`
0%  {bottom: ${lastKeyframes[0] + "px"}}
35%  {bottom: ${lastKeyframes[1] + "px"}}
100% {bottom: ${lastKeyframes[2] + "px"}}
`;

const jumpNow = css`
  ${jump} .3s forwards ease-out;
`;

const fallNow = (props) => css`
  ${fall} .3s forwards ease-out;
`;

/** search in `animations` by frame(scroll) */
const determineAnim = (scroll) => {
  let anim = null;
  const currFrame = scroll;
  let animRange;

  Object.keys(animations).some((k) => {
    animRange = k.split("-");

    if (currFrame < animRange[0] || animRange[1] <= currFrame) {
      return false;
    }

    anim = animations[k];
    return true;
  });

  if (anim === undefined || anim === null || anim === "") return false;

  if (lastAnimType === anim.type) return false;

  lastKeyframes = [
    anim.startHeight,
    (anim.endHeight - anim.startHeight) / 2 + anim.endHeight,
    anim.endHeight,
  ];

  lastAnimType = anim.type;
};

/**
 * @param {*} scroll
 */
const getJumpAnimation = (scroll) => {
  determineAnim(scroll);

  return lastAnimType === "jump" ? jumpNow : fallNow;
};

export default getJumpAnimation;
