import React from 'react';
import { useWindowSizeAndObjectPlacement } from '../../../utils/useWindowSize';
import SceneObject from "../../SceneObject";
import '../../../utils/DroppingText.css';
import Tree from "./Tree";

const GeorgiaEnvironmentLayer = React.memo(props => {

  const { width, height } = useWindowSizeAndObjectPlacement();

  return (
    <SceneObject {...props} name="Georgia Environment Layer">
      <SceneObject
        name="TEXT - Grigoli Vashakidze Interactive Resume"
        transform={{
          position: { x: -450, y: height * 0.75 },
          scale: { x: 800, y: 323 },
          sdposition: { x: -170, y: height * 0.80 },  // Small screen position
          sdscale: { x: 350, y: 350 } // Small screen position
        }}>
        <div className="dropping-text-container">
          <h1 className="dropping-text dropping-text-1" style={{ animationDelay: '0s' }}>Grigoli Vashakidze</h1>
          <h3 className="dropping-text dropping-text-3" style={{ animationDelay: '0.5s' }}>Interactive Resume</h3>
        </div>
      </SceneObject>

      <SceneObject
        name="TEXT - Born in Georgia"
        transform={{
          position: { x: 2000, y: height * 0.75 },
          scale: { x: 550, y: 323 },
          sdposition: { x: 2050, y: height * 0.25 },  // Small screen position
          sdscale: { x: width - width / 10, y: height } // Small screen position
        }}>
        <div className="dropping-text-container">
          <h3 className="dropping-text  dropping-text-4" style={{ animationDelay: '0s' }}>Born in Georgia</h3>
          <h3 className="dropping-text  dropping-text-4" style={{ animationDelay: '0.5s' }}>The country not the state</h3>
        </div>
      </SceneObject>

      <SceneObject
        name="GRASS - BACKGROUNDS"
        color="#16993d"
        transform={{ position: { x: -2000, y: 0 }, scale: { x: 5786, y: 270 } }}
      />
      <SceneObject
        name="GRASS - BACKGROUNDS right slope"
        color="#16993d"
        transform={{
          position: { x: 3701, y: 66 },
          scale: { x: 240, y: 140 },
          rotation: 45
        }}
      />

      <Tree transform={{ position: { x: 2700, y: 235 }, scale: { x: 400, y: 503 } }} type="1" />
      <Tree transform={{ position: { x: 2800, y: 170 }, scale: { x: 450, y: 803 } }} type="6" />
      <Tree transform={{ position: { x: 2900, y: 190 } }} type="3" />
      <Tree transform={{ position: { x: 3100, y: 180 }, scale: { x: 600, y: 803 } }} type="6" />
      <Tree transform={{ position: { x: 3300, y: 170 }, scale: { x: 600, y: 803 } }} type="6" />
      <Tree transform={{ position: { x: 3450, y: 150 } }} type="2" />

    </SceneObject>
  );
});

export default GeorgiaEnvironmentLayer;
