import { useEffect, useState } from 'react';

// Custom hook to get window size and object placement
export const useWindowSizeAndObjectPlacement = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Explicitly type xCoordinate as number | null
  const [yCoordinate, setYCoordinate] = useState<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

    // Define your anchor points, could be dynamic based on window.innerWidth
    const anchorPoints = [400, 500, 600, 700, 800];

    // Find the closest anchor point to initialX
    let closestAnchor = anchorPoints[0];
    let minDistance = Math.abs(window.innerHeight - closestAnchor);

    for (let i = 1; i < anchorPoints.length; i++) {
        const distance = Math.abs(window.innerHeight - anchorPoints[i]);
        if (distance < minDistance) {
        closestAnchor = anchorPoints[i];
        minDistance = distance;
        }
    }

    setYCoordinate(closestAnchor);
    
    };

    handleResize(); // Call initially to set the xCoordinate

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ...windowSize, yCoordinate };
};
