import React from "react";
import { sdsuStageImages } from "../../../utils/imageResources";

import SceneObject from "../../SceneObject";



const AztecUnion = React.memo(props => {
    return (
        <SceneObject
            name="AztecUnion"
            transform={{
                position: props.transform.position,
                scale: props.transform.scale,
                rotation: props.transform.rotation,
                opacity: 1
            }}
            $imgUrl={sdsuStageImages.AztecUnionImg}
        />
    );
});

export default AztecUnion;
