import React from "react";
import Environment from "./components/MainLayers/Environment";
import Ground from "./components/MainLayers/Ground";
import Sky from "./components/MainLayers/Sky";
import Player from "./components/Player/Player";
import { usePlayers } from "./components/Player/usePlayers";
import WithScroll from "./components/WithScroll";
import SEO from "./SEO";
import { allImages } from "./utils/imageResources";
import { usePreloadImages } from "./utils/usePreloadImages";



interface AppProps {
  onScrolling: (event: Event) => void;
  scroll: number;
  scrollDirection: string;
}

const App: React.FC<AppProps> = ({ onScrolling, scroll, scrollDirection }) => {
  const players = usePlayers();

  usePreloadImages(allImages);


  return (
    <div>
      <SEO
        title="Grigoli (Giga) Vashakidze - Full-Stack Software Engineer | DevOps | Cloud Computing" 
        description="Discover Grigoli Vashakidze's interactive resume. A Full-Stack Software Engineer and DevOps specialist based in San Diego, focusing on web development, cloud computing, and automation."
        keywords={[
          "Software", "Developer", "React", "Python", "Cloud", "DevOps", "Automation",
          "Full-Stack Developer San Diego", "Python Software Engineer", "React Web Development",
          "Cloud Computing AWS", "DevOps Engineer", "Data Analytics Python",
          "Agile Methodology", "Giga", "Grigoli", "Vashakidze", "Giga Vashakidze", "Grigoli Vashakidze",
          "Software Consulting", "IT Consulting", "Tech Consulting", "Consulting Services",
          "Backend Developer", "Node.js", "Java", "API Development", "Server-side Development", "Microservices"
        ]}
      />
       {/* <div>
            {imagesLoaded ? (
                allImages.map((url) => <img key={url} src={images[url].src} alt="" />)
            ) : (
                <p>Loading images...</p>
            )}
        </div> */}
      <Player
        onScrolling={onScrolling}
        scroll={scroll}
        scrollDirection={scrollDirection}
        players={players}
      />
      <Sky name="Sky" scroll={scroll} />
      <Environment name="Environment" scroll={scroll} />
      <Ground name="Ground" scroll={scroll} />
    </div>
  );
};

export default React.memo(WithScroll(App));
