import { useEffect, useState } from "react";

// Custom hook for preloading images
export const usePreloadImages = (imageArray: string[]): { images: { [key: string]: HTMLImageElement }, imagesLoaded: boolean } => {
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);
    const [images, setImages] = useState<{ [key: string]: HTMLImageElement }>({});

    useEffect(() => {
        const preloadImages = async () => {
            const loadedImages: { [key: string]: HTMLImageElement } = {};
            try {
                await Promise.all(
                    imageArray.map(
                        (src: string) =>
                            new Promise<void>((resolve, reject) => {
                                const img = new Image();
                                img.src = src;
                                img.onload = () => {
                                    loadedImages[src] = img;
                                    resolve();
                                };
                                img.onerror = () => reject(new Error(`Could not load image at ${src}`));
                            })
                    )
                );
                setImages(loadedImages);
                setImagesLoaded(true);
            } catch (err) {
                console.error("Failed to preload images", err);
            }
        };

        preloadImages();
    }, [imageArray]);

    return { images, imagesLoaded };
};