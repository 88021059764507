import React from "react";
import BillboardWithText from "../../TextData/BillboardTextOverlay";
import resumeDataDict from "../../TextData/DataResume";
import { css, keyframes } from "styled-components";

import SceneObject from "../../SceneObject";
import House from "./House";
import WorkBuilding from "./WorkBuilding";
import { headerImages } from "../../../utils/imageResources";
import { useWindowSizeAndObjectPlacement } from "../../../utils/useWindowSize";
import WorkBuildingBMS from "./WorkBuildingBMS";


const WorkEnvironmentLayer = React.memo(props => {

    const resumeData = resumeDataDict;

    const { yCoordinate } = useWindowSizeAndObjectPlacement();

    // Mitchell
    const header_x_mitchell = "750px";

    const topDropKeyframes_mitchell = props => keyframes`
    100% {left: ${header_x_mitchell}; bottom: ${yCoordinate - yCoordinate / 3}px;}
    `;

    const topDropAnimationCSS_Mitchell = css`
    ${topDropKeyframes_mitchell} 3s ease-in forwards;
    `;

    // Bristol Myers Squibb
    const header_x_bms = "4000px";

    const topDropKeyframes_bms = props => keyframes`
    100% {left: ${header_x_bms}; bottom: ${yCoordinate - yCoordinate / 3}px;}
    `;

    const topDropAnimationCSS_BMS = css`
    ${topDropKeyframes_bms} 3s ease-in forwards;
    `;

    return (
        <SceneObject {...props} name="Work Environment Layer">
            <SceneObject
                name="Sand-background"
                color="moccasin"
                transform={{
                    position: { x: 0, y: 100 },
                    scale: { x: 7000, y: 200 }
                }}
            />
            <SceneObject
                name="Sand-background-left-corner"
                color="grey"
                transform={{
                    position: { x: -527, y: -530 },
                    scale: { x: 400, y: 1000 },
                    rotation: 59
                }}
            />
            <SceneObject
                name="Asphalt-background"
                color="grey"
                transform={{
                    position: { x: 0, y: 200 },
                    scale: { x: 7000, y: 200 }
                }}
            />

            <SceneObject
                name="Header - Mitchell International"
                transform={{
                    position: { x: header_x_mitchell, y: yCoordinate - yCoordinate / 4 },
                    scale: { x: 800, y: 500 },
                    sdposition: { x: 950, y: 450 },  // Small screen position
                    sdscale: { x: 300, y: 300 } // Small screen position
                }}
                $imgUrl={headerImages.Header04}
                animation={props.scroll > 17500 ? topDropAnimationCSS_Mitchell : ""}
            />

            <SceneObject
                name="under-house-ground"
                color="grey"
                transform={{ position: { x: 0, y: 100 }, scale: { x: 700, y: 300 } }}
            />
            <WorkBuilding transform={{ position: { x: -100, y: 150 }, scale: { x: 900, y: 1000 } }} />

            <SceneObject
                name="Work Experience 1 - Internship"
                transform={{
                    position: { x: 1600, y: yCoordinate - yCoordinate / 1.5 },
                    scale: { x: 600, y: 410 },
                    sdposition: { x: 1600, y: 380 },  // Small screen position
                    sdscale: { x: 300, y: 300 } // Small screen position
                }}>
                <BillboardWithText
                    data={resumeDataDict["SoftwareEngineerIntern"]}
                />
            </SceneObject>

            <SceneObject
                name="Work Experience 2"
                transform={{
                    position: { x: 2500, y: yCoordinate - yCoordinate / 1.5 },
                    scale: { x: 600, y: 410 },
                    sdposition: { x: 2500, y: 380 },  // Small screen position
                    sdscale: { x: 300, y: 300 } // Small screen position
                }}>
                <BillboardWithText
                    data={resumeData["SoftwareEngineerI"]}
                />
            </SceneObject>

            <SceneObject
                name="Work Experience 3"
                transform={{
                    position: { x: 3400, y: yCoordinate - yCoordinate / 1.5 },
                    scale: { x: 600, y: 410 },
                    sdposition: { x: 3400, y: 380 },  // Small screen position
                    sdscale: { x: 300, y: 300 } // Small screen position
                }}>
                <BillboardWithText
                    data={resumeData["SoftwareEngineerII"]}
                />
            </SceneObject>


            <SceneObject
                name="Header - Bristol Myers Squibb"
                transform={{
                    position: { x: header_x_bms, y: yCoordinate - yCoordinate / 4 },
                    scale: { x: 800, y: 500 },
                    sdposition: { x: 5000, y: 450 },  // Small screen position
                    sdscale: { x: 300, y: 300 } // Small screen position
                }}
                $imgUrl={headerImages.Header06}
                animation={props.scroll > 17500 ? topDropAnimationCSS_BMS : ""}
            />

            <WorkBuildingBMS transform={{ position: { x: 4400, y: 150 }, scale: { x: 1500, y: 1100 } }} />


            <SceneObject
                name="Work Experience 4"
                transform={{
                    position: { x: 5900, y: yCoordinate - yCoordinate / 1.5 },
                    scale: { x: 600, y: 410 },
                    sdposition: { x: 3400, y: 380 },  // Small screen position
                    sdscale: { x: 300, y: 300 } // Small screen position
                }}>
                <BillboardWithText
                    data={resumeData["ManagerBMS"]}
                />
            </SceneObject>

            <SceneObject
                name="Sand-background-right-corner"
                color="grey"
                transform={{
                    position: { x: 6600, y: -310 },
                    scale: { x: 600, y: 600 },
                    rotation: 59
                }}
            />


            <House transform={{ position: { x: 6700, y: 110 }, scale: { x: 800, y: 1000 } }} />



        </SceneObject>
    );
});

export default WorkEnvironmentLayer;
