import { CSSProperties, FC, memo, useEffect, useState } from "react";
import styled from "styled-components";
import { useWindowSizeAndObjectPlacement } from "../utils/useWindowSize";

interface DimensionProps {
  x?: string | number;
  y?: string | number;
}

interface TransformProps {
  position?: DimensionProps;
  scale?: DimensionProps;
  rotation?: number;
  opacity?: number;
  sdposition?: DimensionProps; // for small display
  sdscale?: DimensionProps; // for small display
}

export interface SceneObjectProps {
  color?: string;
  transform?: TransformProps;
  bgRepeat?: boolean;
  imgUrl?: string;
  animation?: string;
  delay?: number; // new prop for delay in seconds
  isVisible: boolean;
}

// Add isSmallScreen as a prop to StyledSceneObject
interface StyledSceneObjectProps extends SceneObjectProps {
  $isSmallScreen: boolean;
  $isVisible: boolean;
  $imgUrl?: string;
  $animation?: string;
}

// Helper function to calculate CSS values
const calculateValue = (
  dimension: "position" | "scale",
  key: "x" | "y",
  transform?: TransformProps,
  isSmallScreen: boolean = false
): string | undefined => {
  let dimProps: DimensionProps | undefined;

  // Check if transform exists
  if (!transform) {
    return undefined;
  }

  if (isSmallScreen) {
    if ("sdposition" in transform || "sdscale" in transform) {
      dimProps = dimension === "position" ? transform.sdposition : transform.sdscale;
    } else {
      dimProps = transform[dimension];
    }
  } else {
    dimProps = transform[dimension];
  }

  let value = dimProps?.[key];

  if (value === null || value === undefined) {
    return undefined;
  }

  return typeof value === "string" ? value : `${value}px`;
};

const StyledSceneObject = styled.div.attrs<StyledSceneObjectProps>((props) => ({
  style: {
    backgroundColor: props.color ?? "none",
    left: calculateValue("position", "x", props.transform, props.$isSmallScreen),
    bottom: calculateValue("position", "y", props.transform, props.$isSmallScreen),
    width: calculateValue("scale", "x", props.transform, props.$isSmallScreen),
    height: calculateValue("scale", "y", props.transform, props.$isSmallScreen),
    opacity: props.transform?.opacity,
  } as CSSProperties,
}))<StyledSceneObjectProps>`
  position: absolute;
  /* ROTATION */
  transform: rotate(${(props) => props.transform?.rotation ?? 0}deg);
  /* BACKGROUND AND ANIMATION */
  background-size: ${(props) => (props.bgRepeat ? "auto" : "100%")} !important;
  background-repeat: ${(props) => (props.bgRepeat ? "repeat" : "no-repeat")} !important;
  background-position: bottom !important;
  background: url(${(props) => props.$imgUrl});
  // Conditionally set the animation
  animation: ${(props) =>
    props.$isSmallScreen &&
    props.transform &&
    ("sdposition" in props.transform || "sdscale" in props.transform)
      ? "none"
      : props.$animation};
  transition: opacity 0.1s ease-in-out; // new line for transition
  opacity: ${(props) => (props.$isVisible ? 1 : 0)}; // new line for opacity
`;

const SceneObject: FC<SceneObjectProps> = memo((props) => {
  const [isVisible, setIsVisible] = useState(false);

  const isSmallScreen = useWindowSizeAndObjectPlacement().width <= 910;

  useEffect(() => {
    if (props.delay) {
      setTimeout(() => {
        setIsVisible(true);
      }, props.delay * 1000);
    } else {
      setIsVisible(true);
    }
  }, [props.delay]);

  return (
    <StyledSceneObject
      {...props}
      transform={{
        ...props.transform,
        scale: {
          x: calculateValue("scale", "x", props.transform, isSmallScreen),
          y: calculateValue("scale", "y", props.transform, isSmallScreen),
        },
        position: {
          x: calculateValue("position", "x", props.transform, isSmallScreen),
          y: calculateValue("position", "y", props.transform, isSmallScreen),
        },
      }}
      $isSmallScreen={isSmallScreen}
      $isVisible={isVisible}
    />
  );
});

export default SceneObject;
